<script setup>
import { ModalsContainer } from "vue-final-modal";

useHead({
  htmlAttrs: {
    class: "layout-default",
  },
});
</script>

<template>
  <main class="default">
    <DefaultHeader class="header"> </DefaultHeader>
    <DefaultBody class="body">
      <slot />
    </DefaultBody>
    <ModalsContainer />
  </main>
</template>

<style lang="scss">
html.layout-default {
  background-color: var(--default-body-background-color);
  color: var(--default-body-text-color);
}

html.layout-default a {
  color: var(--default-body-text-color);
  // text-decoration: none;
}
</style>

<style lang="scss" scoped>
.default {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  align-self: center;
  width: 100%;
}
</style>
