<script setup lang="ts"></script>

<template>
  <div class="default-body">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.default-body {
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;
  flex-direction: column;
  align-self: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
